import { findBrowser } from "../utils/findBrowserUtil";
import { imagesAVIF } from "./avifImages";
import { imagesPNG } from "./pngImages";
import { imagesWEBP } from "./webpImages";

export const browserType = findBrowser();
export const tag_id = "1";
export const tag_name = "festive offer";
export const offer_id = "1";
export const offer_name = "8K discount";
export const user_id =
  typeof localStorage !== "undefined"
    ? JSON.parse(localStorage.getItem("user_id"))
    : "";

export const imagesList = browserType === "Chrome" ? imagesAVIF : imagesPNG;

export const leads_host = process.env.NEXT_PUBLIC_API_ENDPOINT;
// process.env.NODE_ENV === "development" ||
// window.location.href?.includes("staging")
//   ? "https://non-prod-backend.turnocloud.com/"
//   : "https://api.turnoclub.com";

export const cashfree_scripts = process.env.NEXT_PUBLIC_CASHFREE_SCRIPT_LINK;
// process.env.NODE_ENV === "development" ||
// window.location.href?.includes("staging")
//   ? "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js"
//   : "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.prod.js";

export const leads_auth_token = "2C8aVDh0YlRe3TOYubAJBttihFAzX2Vc";

// export const EvOptions = [
//   { value: "Piaggio Ape Electrik", label: "Piaggio Ape Electrik" },
//   { value: "Euler Hiload", label: "Euler Hiload" },
//   { value: "Mahindra Treo", label: "Mahindra Treo" },
//   { value: "E-TRIO Touro MAX+", label: "E-TRIO Touro MAX+" },
//   { value: "AltiGreen neEV", label: "AltiGreen neEV" },
//   { value: "OSM Rage+", label: "OSM Rage+" },
// ];

export const EvOptions = [
  { value: "Piaggio Ape E-Xtra FX", label: "Piaggio Ape E-Xtra FX" },
  { value: "Mahindra Treo", label: "Mahindra Treo" },
  { value: "E-TRIO Touro MAX++", label: "E-TRIO Touro MAX++" },
  { value: "OSM Rage + 8.5 KWh", label: "OSM Rage + 8.5 KWh" },
  { value: "Euler HiLoad", label: "Euler HiLoad" },
  { value: "OSM Rage +", label: "OSM Rage +" },
  { value: "OSM Rage + Frost", label: "OSM Rage + Frost" },
  { value: "Piaggio Ape E Xtra FX Max", label: "Piaggio Ape E Xtra FX Max" },
  { value: "Mahindra Zor Grand", label: "Mahindra Zor Grand" },
  { value: "Euler HiLoad 170 Cu. Ft", label: "Euler HiLoad 170 Cu. Ft" },
  { value: "HiLoad PV", label: "HiLoad PV" },
];

export const StateOptions = [
  { value: "Karnataka", label: "Karnataka" },
  { value: "Delhi", label: "Delhi/NCR" },
  { value: "Telangana", label: "Telangana" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh"},
  { value: "Others", label: "Others" },
];
export const StateOptionsForEMI = [
  { value: "Karnataka", label: "Karnataka" },
  { value: "Telangana", label: "Telangana" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh"},
  { value: "Others", label: "Others" },
];
export const StateOptionsForQuotation = [
  // { value: "81a8ed77-6960-4744-96c2-cb3668ec999a", label: "Karnataka" },
  { value: "Karnataka", label: "Karnataka" },
  // { value: "f5ca8e94-e4d0-4384-817c-dfc7cfdde920", label: "Telangana" },
  { value: "Telangana", label: "Telangana" },
  // { value: "314ba937-6413-4b77-8c19-410b5211b4f9", label: "Delhi/NCR" },
  { value: "Delhi", label: "Delhi/NCR" },
  // { value: "1364995f-246c-483c-ba1a-5729bcbd7603", label:"Andhra Pradesh" },
  { value: "Andhrapradesh", label: "Andhra Pradesh" },
  { value: "Madhyapradesh", label: "Madhya Pradesh" },
  // { value: "de2b54f4-cba1-40cd-a119-e2a2534c390a", label: "Gujarat" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh"},
  { value: "Others", label: "Others" },
];
export const VehiclesOptions = [
  { type: "title", label: "Mahindra", value: "none" },
  {
    value: 2,
    label: "Mahindra Treo",
    vehicle_logo: imagesWEBP?.brandLogos?.mahindra,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 2,
        type: "flat_bed",
        thubnailTitle: "Flat Bed",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Treo+Zor/Mahindra-Treo-Zor-pickup.png`,
      },
      flat_bed: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Treo+Zor/Mahindra-Treo-Zor-flatbed.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Treo+Zor/Mahindra-Treo-Zor-container.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 367225,
          exshowroom: 343582,
          insuarance: 16143,
          registration: 7500,
          handling: 0,
          fabrication: 0,
        },
        full_body: {
          price: 405291,
          exshowroom: 380184,
          insuarance: 17607,
          registration: 7500,
          handling: 0,
          fabrication: 0,
        },
        pickup: {
          price: 373510,
          exshowroom: 349867,
          insuarance: 16143,
          registration: 7500,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 355000,
        },
        full_body: {
          price: 393791,
        },
        pickup: {
          price: 362762,
        },
      },
      Telangana: {
        flat_bed: {
          price: 344000,
        },
        full_body: {
          price: 381000,
        },
        pickup: {
          price: 362762,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 301000,
        },
        full_body: {
          price: 337000,
        },
        pickup: {
          price: 307000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 301000,
        },
        full_body: {
          price: 337000,
        },
        pickup: {
          price: 307000,
        },
      },

      Andhrapradesh: {
        flat_bed: {
          price: 333000,
          emiPrice: 11606,
        },
        full_body: {
          price: 369000,
          emiPrice: 14257,
        },
        pickup: {
          price: 362000,
          emiPrice: 12680,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 382000,
        },
        full_body: {
          price: 422000,
        },
        pickup: {
          price: 389000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 387000,
        },
        full_body: {
          price: 431000,
        },
        pickup: {
          price: 389000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 330000,
        },
        full_body: {
          price: 480000,
        },
        pickup: {
          price: 341000,
        },
      },
      Others: {
        flat_bed: {
          price: 387000,
        },
        full_body: {
          price: 431000,
        },
        pickup: {
          price: 389000,
        },
      },
    },
  },
  {
    value: 9,
    label: "Mahindra Zor Grand",
    vehicle_logo: imagesWEBP?.brandLogos?.mahindra,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Zor+Grand/Mahindra-Zor-Grand--pickup.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Zor+Grand/Mahindra-Zor-Grand-container.png`,
      },
    },
    prices: {
      Karnataka: {
        full_body: {
          price: 448770,
          exshowroom: 420924,
          insuarance: 20346,
          registration: 7500,
          handling: 0,
          fabrication: 0,
        },
        pickup: {
          price: 416483,
          exshowroom: 390174,
          insuarance: 18809,
          registration: 7500,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        full_body: {
          price: 449000,
        },
        pickup: {
          price: 411000,
        },
      },
      Telangana: {
        full_body: {
          price: 429000,
        },
        pickup: {
          price: 369000,
        },
      },
      Maharashtra: {
        full_body: {
          price: 383000,
        },
        pickup: {
          price: 414000,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 383000,
        },
        pickup: {
          price: 414000,
        },
      },
      Andhrapradesh: {
        full_body: {
          price: 416000,
          emiPrice: 14257,
        },
        pickup: {
          price: 378000,
          emiPrice: 12680,
        },
      },
      Gujarat: {
        full_body: {
          price: 474000,
        },
        pickup: {
          price: 435000,
        },
      },
      Delhi: {
        full_body: {
          price: 441000,
        },
        pickup: {
          price: 435000,
        },
      },
      Madhyapradesh: {
        full_body: {
          price: 441000,
        },
        pickup: {
          price: 435000,
        },
      },
      Others: {
        full_body: {
          price: 441000,
        },
        pickup: {
          price: 435000,
        },
      },
    },
  },
  {
    value: 118,
    label: "Mahindra Zor Grand Plus",
    vehicle_logo: imagesWEBP?.brandLogos?.mahindra,
    images: [
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Zor+Grand/Mahindra-Zor-Grand-container.png`,
      },
    },
    prices: {
      Karnataka: {
        full_body: {
          price: 454763,
          exshowroom: 426661,
          insuarance: 20602,
          registration: 7500,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        full_body: {
          price: 449000,
        },
      },
      Telangana: {
        full_body: {
          price: 429000,
        },
      },
      Maharashtra: {
        full_body: {
          price: 383000,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 383000,
        },
      },
      Andhrapradesh: {
        full_body: {
          price: 416000,
          emiPrice: 14257,
        },
      },
      Gujarat: {
        full_body: {
          price: 474000,
        },
      },
      Delhi: {
        full_body: {
          price: 441000,
        },
      },
      Madhyapradesh: {
        full_body: {
          price: 441000,
        },
      },
      Others: {
        full_body: {
          price: 441000,
        },
      },
    },
  },
  { type: "title", label: "Piaggio", value: "none" },
  {
    value: 1,
    label: "Piaggio Ape E-Xtra FX",
    vehicle_logo: imagesWEBP?.brandLogos?.piaggio,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 2,
        type: "flat_bed",
        thubnailTitle: "Flat Bed",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Piaggio+FX/Piaggio-Ape-Fx-pickup.png`,
      },
      flat_bed: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Piaggio+FX/Piaggio-Ape-Fx-flatbed.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Piaggio+FX/Piaggio-Ape-Fx-container.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 353000,
          exshowroom: 364906,
          insuarance: 12794,
          registration: 12500,
          handling: 3000,
          fabrication: 0,
        },
        full_body: {
          price: 423000,
          exshowroom: 364906,
          insuarance: 12794,
          registration: 12500,
          handling: 3000,
          fabrication: 42000,
        },
        pickup: {
          price: 382000,
          exshowroom: 367393,
          insuarance: 12807,
          registration: 12500,
          handling: 3000,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 419000,
        },
        pickup: {
          price: 360000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 352000,
        },
        full_body: {
          price: 401000,
        },
        pickup: {
          price: 360000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 423000,
        },
        pickup: {
          price: 382000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 423000,
        },
        pickup: {
          price: 382000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 400000,
        },
        pickup: {
          price: 360000,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 423000,
        },
        pickup: {
          price: 411000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 419000,
        },
        pickup: {
          price: 379000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 373000,
        },
        full_body: {
          price: 412000,
        },
        pickup: {
          price: 379000,
        },
      },
      Others: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 423000,
        },
        pickup: {
          price: 382000,
        },
      },
    },
  },
  {
    value: 8,
    label: "Piaggio Ape E Xtra FX Max",
    vehicle_logo: imagesWEBP?.brandLogos?.piaggio,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 2,
        type: "flat_bed",
        thubnailTitle: "Flat Bed",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Piaggio+FX+max/Piaggio-Ape-Fx-max-pickup.png`,
      },
      flat_bed: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Piaggio+FX+max/Piaggio-Ape-Fx-max-flatbed.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Piaggio+FX+max/Piaggio-Ape-Fx-maxContainer.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 393644,
          exshowroom: 364906,
          insuarance: 16000,
          registration: 12738,
          handling: 3000,
          fabrication: 0,
        },
        full_body: {
          price: 438644,
          exshowroom: 364906,
          insuarance: 16000,
          registration: 12738,
          handling: 3000,
          fabrication: 45000,
        },
        pickup: {
          price: 396178,
          exshowroom: 367393,
          insuarance: 16000,
          registration: 12785,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 393000,
        },
        full_body: {
          price: 453000,
        },
        pickup: {
          price: 395000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 359000,
        },
        full_body: {
          price: 401000,
        },
        pickup: {
          price: 371000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 365000,
        },
        full_body: {
          price: 434000,
        },
        pickup: {
          price: 393000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 365000,
        },
        full_body: {
          price: 434000,
        },
        pickup: {
          price: 393000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 365000,
        },
        full_body: {
          price: 434000,
        },
        pickup: {
          price: 393000,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 421000,
        },
        full_body: {
          price: 460000,
        },
        pickup: {
          price: 424000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 421000,
        },
        full_body: {
          price: 460000,
        },
        pickup: {
          price: 424000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 373000,
        },
        full_body: {
          price: 418000,
        },
        pickup: {
          price: 391000,
        },
      },
      Others: {
        flat_bed: {
          price: 373000,
        },
        full_body: {
          price: 418000,
        },
        pickup: {
          price: 391000,
        },
      },
    },
  },
  { type: "title", label: "Euler", value: "none" },
  {
    value: 14,
    label: "HILOAD DV - 130 CC",
    vehicle_logo: imagesWEBP?.brandLogos?.euler,
    images: [
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Euler/Euler-HiLoad-container.png`,
      },
    },
    prices: {
      Karnataka: {
        full_body: {
          price: 452599,
          exshowroom: 429999,
          insuarance: 15000,
          registration: 7600,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        full_body: {
          price: 459000,
        },
      },
      Telangana: {
        full_body: {
          price: 426000,
        },
      },
      Maharashtra: {
        full_body: {
          price: 428000,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 428000,
        },
      },
      Andhrapradesh: {
        full_body: {
          price: 428000,
        },
      },
      Gujarat: {
        full_body: {
          price: 428000,
        },
      },
      Delhi: {
        full_body: {
          price: 428000,
        },
      },
      Madhyapradesh: {
        full_body: {
          price: 428000,
        },
      },
      Others: {
        full_body: {
          price: 428000,
        },
      },
    },
  },
  {
    value: 15,
    label: "HILOAD Flat Bed",
    vehicle_logo: imagesWEBP?.brandLogos?.euler,
    images: [
      {
        id: 2,
        type: "flat_bed",
        thubnailTitle: "Flat Bed",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      },
    ],
    quotationImgs: {
      flat_bed: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Euler/Euler-HiLoad-flatbed.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 426599,
          exshowroom: 409999,
          insuarance: 9000,
          registration: 7600,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 393000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 421000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 403000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 403000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 403000,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 403000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 403000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 403000,
        },
      },
      Others: {
        flat_bed: {
          price: 403000,
        },
      },
    },
  },
  {
    value: 16,
    label: "Euler HiLoad 170 Cu. Ft",
    vehicle_logo: imagesWEBP?.brandLogos?.euler,
    images: [
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Euler/Euler-HiLoad-container.png`,
      },
    },
    prices: {
      Karnataka: {
        full_body: {
          price: 462600,
          exshowroom: 429999,
          insuarance: 15000,
          registration: 7600,
          handling: 0,
          fabrication: 10001,
        },
      },
      TamilNadu: {
        full_body: {
          price: 459000,
        },
      },
      Telangana: {
        full_body: {
          price: 426000,
        },
      },
      Maharashtra: {
        full_body: {
          price: 428000,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 428000,
        },
      },
      Andhrapradesh: {
        full_body: {
          price: 428000,
        },
      },
      Gujarat: {
        full_body: {
          price: 428000,
        },
      },
      Delhi: {
        full_body: {
          price: 428000,
        },
      },
      Madhyapradesh: {
        full_body: {
          price: 428000,
        },
      },
      Others: {
        full_body: {
          price: 428000,
        },
      },
    },
  },
  {
    value: 17,
    label: "Euler HiLoad PV",
    vehicle_logo: imagesWEBP?.brandLogos?.euler,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Euler/Euler-HiLoad-pickup.png`,
      },
    },
    prices: {
      Karnataka: {
        pickup: {
          price: 432599,
          exshowroom: 409999,
          insuarance: 15000,
          registration: 7600,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        pickup: {
          price: 435000,
        },
      },
      Telangana: {
        pickup: {
          price: 446000,
        },
      },
      Maharashtra: {
        pickup: {
          price: 308000,
        },
      },
      "Uttar Pradesh": {
        pickup: {
          price: 308000,
        },
      },
      Andhrapradesh: {
        pickup: {
          price: 308000,
        },
      },
      Gujarat: {
        pickup: {
          price: 308000,
        },
      },
      Delhi: {
        pickup: {
          price: 308000,
        },
      },
      Madhyapradesh: {
        pickup: {
          price: 308000,
        },
      },
      Others: {
        pickup: {
          price: 308000,
        },
      },
    },
  },
  { type: "title", label: "E-trio", value: "none" },
  {
    value: 3,
    label: "E-TRIO Touro MAX++",
    vehicle_logo: imagesWEBP?.brandLogos?.etrio,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 2,
        type: "flat_bed",
        thubnailTitle: "Flat Bed",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Etrio/Etrio-Touro-Max%2B%2B-pickup.png`,
      },
      flat_bed: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Etrio/Etrio-Touro-Max%2B%2B-flatbed.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Etrio/Etrio-Touro-Max%2B%2B-container.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 417900,
          exshowroom: 398400,
          insuarance: 12000,
          registration: 7500,
          handling: 1000,
          fabrication: 0,
        },
        full_body: {
          price: 460900,
          exshowroom: 398400,
          insuarance: 13000,
          registration: 7500,
          handling: 1000,
          fabrication: 42000,
        },
        pickup: {
          price: 422600,
          exshowroom: 402600,
          insuarance: 12500,
          registration: 7500,
          handling: 1000,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 432000,
        },
        full_body: {
          price: 419000,
        },
        pickup: {
          price: 389000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 432000,
        },
        full_body: {
          price: 398000,
        },
        pickup: {
          price: 356000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 382000,
        },
        full_body: {
          price: 416000,
        },
        pickup: {
          price: 387000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 382000,
        },
        full_body: {
          price: 416000,
        },
        pickup: {
          price: 387000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 382000,
        },
        full_body: {
          price: 416000,
        },
        pickup: {
          price: 387000,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 432000,
        },
        full_body: {
          price: 468000,
        },
        pickup: {
          price: 437000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 369000,
        },
        full_body: {
          price: 400000,
        },
        pickup: {
          price: 373000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 369000,
        },
        full_body: {
          price: 400000,
        },
        pickup: {
          price: 373000,
        },
      },
      Others: {
        flat_bed: {
          price: 369000,
        },
        full_body: {
          price: 400000,
        },
        pickup: {
          price: 373000,
        },
      },
    },
  },
  { type: "title", label: "OSM", value: "none" },
  {
    value: 6,
    label: "OSM Rage + 8.5kwh",
    vehicle_logo: imagesWEBP?.brandLogos?.osm,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/OSM/OSM-Rage%2B-pickup.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/OSM/OSM-Rage%2B-container.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 366900,
          exshowroom: 341300,
          insuarance: 9600,
          registration: 13000,
          handling: 3000,
          fabrication: 0,
        },
        full_body: {
          price: 409950,
          exshowroom: 384350,
          insuarance: 9600,
          registration: 13000,
          handling: 3000,
          fabrication: 0,
        },
        pickup: {
          price: 379500,
          exshowroom: 353900,
          insuarance: 9600,
          registration: 13000,
          handling: 3000,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 366000,
        },
        full_body: {
          price: 397000,
        },
        pickup: {
          price: 366000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 374000,
        },
        pickup: {
          price: 360000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
        pickup: {
          price: 385000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
        pickup: {
          price: 385000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
        pickup: {
          price: 385000,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
        pickup: {
          price: 385000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
        pickup: {
          price: 385000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 373000,
        },
        full_body: {
          price: 435000,
        },
        pickup: {
          price: 410000,
        },
      },
      Others: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
        pickup: {
          price: 385000,
        },
      },
    },
  },
  {
    value: 7,
    label: "OSM Rage + Frost",
    vehicle_logo: imagesWEBP?.brandLogos?.osm,
    images: [
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/OSM/OSM-Rage%2B-frost.png`,
      },
    },
    prices: {
      Karnataka: {
        full_body: {
          price: 843000,
          exshowroom: 810750,
          insuarance: 16250,
          registration: 13000,
          handling: 3000,
          fabrication: 0,
        },
      },
      TamilNadu: {
        full_body: {
          price: 843000,
        },
      },
      Telangana: {
        full_body: {
          price: 843000,
        },
      },
      Maharashtra: {
        full_body: {
          price: 843000,
        },
      },
      "Uttar Pradesh": {
        full_body: {
          price: 843000,
        },
      },
      Andhrapradesh: {
        full_body: {
          price: 843000,
        },
      },
      Gujarat: {
        full_body: {
          price: 843000,
        },
      },
      Delhi: {
        full_body: {
          price: 843000,
        },
      },
      Madhyapradesh: {
        full_body: {
          price: 843000,
        },
      },
      Others: {
        full_body: {
          price: 843000,
        },
      },
    },
  },
  {
    value: 4,
    label: "OSM Rage + 10.8 KWh",
    vehicle_logo: imagesWEBP?.brandLogos?.osm,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/OSM/OSM-Rage%2B-pickup.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/OSM/OSM-Rage%2B-container.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 401200,
          exshowroom: 375300,
          insuarance: 9900,
          registration: 13000,
          handling: 3000,
          fabrication: 0,
        },
        full_body: {
          price: 431650,
          exshowroom: 405750,
          insuarance: 9900,
          registration: 13000,
          handling: 3000,
          fabrication: 0,
        },
        pickup: {
          price: 413800,
          exshowroom: 387900,
          insuarance: 9900,
          registration: 13000,
          handling: 3000,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 401000,
        },
        full_body: {
          price: 431000,
        },
        pickup: {
          price: 413000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 404000,
        },
        full_body: {
          price: 424000,
        },
        pickup: {
          price: 409000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 406000,
        },
        full_body: {
          price: 431000,
        },
        pickup: {
          price: 416000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 406000,
        },
        full_body: {
          price: 431000,
        },
        pickup: {
          price: 416000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 406000,
        },
        full_body: {
          price: 475000,
        },
        pickup: {
          price: 465000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 406000,
        },
        full_body: {
          price: 431000,
        },
        pickup: {
          price: 416000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 406000,
        },
        full_body: {
          price: 431000,
        },
        pickup: {
          price: 416000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 421000,
        },
        full_body: {
          price: 536000,
        },
        pickup: {
          price: 518000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 440000,
        },
        full_body: {
          price: 465000,
        },
        pickup: {
          price: 440000,
        },
      },
      Others: {
        flat_bed: {
          price: 440000,
        },
        full_body: {
          price: 465000,
        },
        pickup: {
          price: 440000,
        },
      },
    },
  },
  { type: "title", label: "AltiGreen", value: "none" },
  {
    value: 12,
    label: "Altigreen neEV",
    vehicle_logo: imagesWEBP?.brandLogos?.osm,
    images: [
      {
        id: 2,
        type: "flat_bed",
        thubnailTitle: "Flat Bed",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      flat_bed: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Altigreen/Altigreen-NeEv-flatbed.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Altigreen/Altigreen-NeEvContainer.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 426136,
          exshowroom: 407636,
          insuarance: 9000,
          registration: 9500,
          handling: 0,
          fabrication: 0,
        },
        full_body: {
          price: 426136,
          exshowroom: 375300,
          insuarance: 9000,
          registration: 9500,
          handling: 0,
          fabrication: 0,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 366000,
        },
        full_body: {
          price: 397000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 374000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 373000,
        },
        full_body: {
          price: 435000,
        },
      },
      Others: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
    },
  },
  {
    value: 13,
    label: "Altigreen neEV Tez",
    vehicle_logo: imagesWEBP?.brandLogos?.osm,
    images: [
      {
        id: 1,
        type: "pickup",
        thubnailTitle: "PickUp",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickupselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Pickup.webp`,
      },
      {
        id: 2,
        type: "flat_bed",
        thubnailTitle: "Flat Bed",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbedselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Flatbed.webp`,
      },
      {
        id: 3,
        type: "full_body",
        thubnailTitle: "Full Body",
        thumbnailselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbodyselected.webp`,
        thumbnailUnselected: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/Vehicles/thumbnail/Fullbody.webp`,
      },
    ],
    quotationImgs: {
      flat_bed: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Altigreen/Altigreen-NeEv-flatbed.png`,
      },
      full_body: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Altigreen/Altigreen-NeEvContainer.png`,
      },

      pickup: {
        quotationImg: `${process.env.NEXT_PUBLIC_CDN_LINK}v2/quotation-png/Altigreen/Altigreen-NeEv-pickup.png`,
      },
    },
    prices: {
      Karnataka: {
        flat_bed: {
          price: 422227,
          exshowroom: 403227,
          insuarance: 9500,
          registration: 9500,
          handling: 0,
          fabrication: 0,
        },
        pickup: {
          price: 426136,
          exshowroom: 408227,
          insuarance: 9500,
          registration: 16500,
          handling: 0,
          fabrication: 0,
        },
        full_body: {
          price: 474027,
          exshowroom: 403227,
          insuarance: 9500,
          registration: 16500,
          handling: 0,
          fabrication: 44800,
        },
      },
      TamilNadu: {
        flat_bed: {
          price: 366000,
        },
        full_body: {
          price: 397000,
        },
      },
      Telangana: {
        flat_bed: {
          price: 353000,
        },
        full_body: {
          price: 374000,
        },
      },
      Maharashtra: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      "Uttar Pradesh": {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Andhrapradesh: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Gujarat: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Delhi: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
      Madhyapradesh: {
        flat_bed: {
          price: 373000,
        },
        full_body: {
          price: 435000,
        },
      },
      Others: {
        flat_bed: {
          price: 372000,
        },
        full_body: {
          price: 403000,
        },
      },
    },
  },
];

export const cityOptionsForQuotation = [
  "Mumbai",
  "Nagpur",
  "Pune",
  "Aurangabad",
  "Bengaluru",
  "Chennai",
  "Coimbatore",
  "Madurai",
  "Vijayawada",
  "Kadapa",
  "Kurnool",
  "Ananthapur",
  "Ongole",
  "Guntur",
  "Tirupati",
  "Vishakhapatnam",
  "Nellore",
  "Hydrabad",
  "Vishakapatnam",
  "Ahmedabad",
  "Surat",
  "Indore",
  "Gwalior",
  "Bhopal",
  "Delhi",
  "Gurgaon",
  "Noida",
  "Ghaziabad",
];

export const versionOptions = [
  { value: 1, label: "On-road Price only" },
  { value: 2, label: "with Discount" },
  { value: 3, label: "with Discount+Finance" },
  { value: 4, label: "with Finance" },
  { value: 5, label: "Pricing breakdown" },
];

export const Language = [
  { value: "en", label: "English" },
  { value: "kan", label: "ಕನ್ನಡ" },
  { value: "tam", label: "தமிழ்" },
  { value: "tel", label: "తెలుగు" },
  { value: "mar", label: "मराठी" },
];

export const stateArray = [
  {
    name: "Karnataka",
    imageName: "karnatakaState",
  },
  {
    name: "Tamil Nadu",
    imageName: "tamilNaduState",
  },
  {
    name: "Maharashtra",
    imageName: "maharashtraState",
  },
  {
    name: "Uttar Pradesh",
    imageName: "maharashtraState",
  },
  {
    name: "Telangana",
    imageName: "telenganaState",
  },
  {
    name: "Others",
    imageName: "otherState",
  },
];

export const cityArray = [
  {
    name: "Bangalore",
    imageName: "bengaluruCity",
  },
  {
    name: "Hyderabad",
    imageName: "hyderabadCity",
  },
  {
    name: "Mumbai",
    imageName: "mumbaiCity",
  },
  {
    name: "Pune",
    imageName: "puneCity",
  },
  {
    name: "Chennai",
    imageName: "chennaiCity",
  },
  {
    name: "Delhi",
    imageName: "delhincrCity",
  },
  {
    name: "Others",
    imageName: "othersCity",
  },
];
