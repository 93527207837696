import { CssBaseline, ThemeProvider } from "@mui/material";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import axios from "axios";
import Head from "next/head";
import Script from "next/script";
import React, { useEffect } from "react";

import { SpeedInsights } from "@vercel/speed-insights/next";
import { theme } from "../src/constants/theme";
import { WhatsAppPopupProvider } from "../src/context/context";
import { NavbarActiveItemProvider } from "../src/context/navbarContext";
import { MemoizedStatePopup } from "../src/Popups/statePopup/StatePopup";
import { MemoizedSnackBar } from "../src/SDK/snackbar";
import { locationTracking } from "../src/services/locationService";
import "../src/styles/neededCss.css";
import { CapturePageHit } from "../src/utils/capturePageHit";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_ENDPOINT;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.src = process.env.NEXT_PUBLIC_CASHFREE_SCRIPT_LINK;
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    CapturePageHit();
    locationTracking();
  }, []);

  return (
    <>
      {/* <Script async src={process.env.NEXT_PUBLIC_CASHFREE_SCRIPT_LINK}></Script> */}
      <Head>
        <>
          <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-NF49NHPB76"
            defer
          ></Script>

          <Script id="gtag" strategy="afterInteractive" defer>
            {` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-NF49NHPB76');`}
          </Script>

          {/* Google tag (gtag.js) */}
          {/* <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=UA-224876127-1"
          ></Script> */}

          {/* <Script id="gtag" strategy="afterInteractive">
            {`
             window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "UA-224876127-1");
            `}
          </Script> */}

          <Script
            strategy="afterInteractive"
            src={process.env.NEXT_PUBLIC_CASHFREE_SCRIPT_LINK}
            defer
          ></Script>
        </>
      </Head>
      <WhatsAppPopupProvider>
        <ThemeProvider theme={theme}>
          {/* <ContextProviderWrapper> */}
          <NavbarActiveItemProvider>
            <CssBaseline>
              {/* <MemoizedWhatsAppDrawer /> */}
              {/* <MemoizedWhatsAppPopup /> */}
              {/* <MemoizedWhatsAppPopup />
              <MemoizedSnackBar />
                <MemoizedWhatsAppDrawer />
                {mobileView ? (
                  <MemoizedWhatsAppDrawer />
                ) : (
                  <MemoizedWhatsAppPopup />
                )} */}
              <MemoizedStatePopup />
              {/* <MemoizedCityPopup /> */}
              <MemoizedSnackBar />

              <Component {...pageProps} />
              <SpeedInsights />
            </CssBaseline>
          </NavbarActiveItemProvider>
          {/* </ContextProviderWrapper> */}
        </ThemeProvider>
      </WhatsAppPopupProvider>

      <VercelAnalytics />
    </>
  );
};

export default App;
